export const lettersNumbersPunctuation = /^[a-z0-9\s._,']+$/i;

export const lettersAndPunctuation = /^[a-z\s._,']+$/i;

export const unicodeLettersPunctuation = /^[\w\-\sá-úÁ-Ú._,']+$/i;

export const workPhone =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/i;

export const zipCode = /^\d{5}(?:[-\s]\d{4})?$/i;

export const lettersAndNumbers = /^[a-zA-Z0-9]+$/i;

export const onlyNumbers = /^[0-9]+$/i;

export const decimalNumbers = /^(([1-9]*)|(([1-9]*)\.([0-9]*)))$/;

export const facilityCd = /^[A-Z]([A-Z0-9])?$/;

export const semicolonSeparatedEmailList =
  /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z0-9_-]+(;\s*[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z0-9_-]+)*$/;
